var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormulateInput", {
        attrs: {
          name: "taxes",
          type: "group",
          "error-behavior": "live",
          repeatable: true,
          "add-label": _vm.$t("Adicionar configuração"),
          limit: _vm.isStockLosses
            ? _vm.invoiceStockLossesReasonOptions().length
            : 1,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var index = ref.index
              return [
                _c(
                  "b-row",
                  [
                    _vm.isStockLosses
                      ? _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              class: { required: _vm.isStockLosses },
                              attrs: {
                                id: "tax-loss-reason-" + index,
                                name: "stockLossesReason",
                                type: "vue-select",
                                options: _vm.invoiceStockLossesReasonOptions(),
                                label: _vm.$t("Motivo baixa de estoque"),
                                title: _vm.$t("Selecione"),
                                validation: _vm.isStockLosses ? "required" : "",
                                clearable: false,
                                size: "5",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-st-ipi-" + index,
                            name: "stIpi",
                            type: "vue-select",
                            label: _vm.$t("Cst Ipi Saída"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboCstIpiOutput,
                            validation: "required",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-ipi-percent-" + index,
                            name: "ipiPercent",
                            type: "text-percetage",
                            validation: "required|number|min:0",
                            label: _vm.$t("Alíquota Ipi"),
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-st-pis-" + index,
                            name: "stPis",
                            type: "vue-select",
                            label: _vm.$t("Cst Pis Saída"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboCstPisCofinsOutput,
                            validation: "required",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-pis-percent-" + index,
                            name: "pisPercent",
                            type: "text-percetage",
                            validation: "required|number",
                            label: _vm.$t("Alíquota Pis"),
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-st-cofins-" + index,
                            name: "stCofins",
                            type: "vue-select",
                            label: _vm.$t("Cst Cofins Saída"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboCstPisCofinsOutput,
                            validation: "required",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-cofins-percent-" + index,
                            name: "cofinsPercent",
                            type: "text-percetage",
                            validation: "required|number|min:0",
                            label: _vm.$t("Alíquota Cofins"),
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-st-icms-" + index,
                            name: "stIcms",
                            type: "vue-select",
                            label: _vm.$t("Cst Icms"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboCstIcms,
                            validation: "required",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "tax-icms-csosn-" + index,
                            name: "csosnIcms",
                            type: "vue-select",
                            label: _vm.$t("Csosn Icms"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboCsosnIcms,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-icms-percent-" + index,
                            name: "icmsPercent",
                            type: "text-percetage",
                            validation: "required|number|min:0",
                            currency: "%",
                            precision: 4,
                            label: _vm.$t("Alíquota Icms"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-icmsBaseReduction-percent-" + index,
                            name: "icmsBaseReductionPercent",
                            type: "text-percetage",
                            label: _vm.$t("Redução Base Icms"),
                            validation: "required|number|min:0",
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-icms-iva-" + index,
                            name: "iva",
                            type: "text-percetage",
                            label: _vm.$t("Iva"),
                            validation: "required|number|min:0",
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-icms-st-percent-" + index,
                            name: "icmsStPercent",
                            type: "text-percetage",
                            label: _vm.$t("Alíquota Icms ST"),
                            validation: "required|number|min:0",
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-icms-st-base-reduction-percent-" + index,
                            name: "icmsStBaseReductionPercent",
                            type: "text-percetage",
                            label: _vm.$t("Redução Base Icms ST"),
                            validation: "required|number|min:0",
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-icms-fcp-percent-" + index,
                            name: "fcpPercent",
                            type: "text-percetage",
                            label: _vm.$t(
                              "Alíquota Fundo de Combate à Pobreza"
                            ),
                            validation: "required|number|min:0",
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "tax-icms-fcp-st-percent-" + index,
                            name: "fcpStPercent",
                            type: "text-percetage",
                            label: _vm.$t(
                              "Alíquota Fundo de Combate à Pobreza ST"
                            ),
                            validation: "required|number|min:0",
                            currency: "%",
                            precision: 4,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.taxes,
          callback: function ($$v) {
            _vm.taxes = $$v
          },
          expression: "taxes",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }