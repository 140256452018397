<template>
  <section>
    <FormulateForm
      ref="cfopForm"
      name="cfopForm"
    >
      <b-card-actions
        no-actions
        :show-loading="saving"
        :title="$t('Dados do Cfop')"
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="code"
              v-model="cfop.code"
              :type="isEdit ? 'label' : 'number'"
              minlength="4"
              maxlength="4"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              class="required"
              :label="$t('Código')"
              :placeholder="$t('Código')"
              validation="required"
            />
          </b-col>
          <b-col md="5">
            <FormulateInput
              id="description"
              v-model="cfop.description"
              type="textarea"
              class="required"
              rows="3"
              :label="$t('Descrição')"
              :instruction="$t('Informe a descrição do CFOP')"
              validation="required"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="movement-type"
              v-model="cfop.movementType"
              type="vue-select"
              class="required"
              :label="$t('Tipo de Movimento')"
              :placeholder="$t('Selecione um tipo de movimento')"
              :options="movementTypeOptions()"
              validation="required"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="cfop-type"
              v-model="cfop.cfopType"
              type="vue-select"
              class="required"
              :label="$t('Tipo de Cfop')"
              :placeholder="$t('Selecione um tipo de cfop')"
              :options="cfopTypeOptions()"
              validation="required"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        no-actions
        :show-loading="saving"
        :title="$t('Configuração para utilização em funcionalidades do sistema')"
      >
        <b-row>
          <b-col md="12">
            <FormulateInput
              id="cfop-invoice_types"
              v-model="cfop.cfopInvoiceTypes"
              type="vue-select"
              :label="$t('Tipo de Nota Fiscal relacionado')"
              :placeholder="$t('Selecione')"
              :options="invoiceCategoryOptions()"
              :instructions="[
                {
                  text: $t(
                    'Habilita este Cfop como opção no campo Natureza da Operação de acordo com o tipo da Nota Fiscal a ser criado/editado'
                  ),
                },
                { text: $t('Pode ser adicionado mais de um tipo de Nota Fiscal') },
              ]"
              multiple
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              id="cfop-cfop_relateds"
              v-model="cfop.cfopRelatedCfops"
              type="vue-select"
              :label="$t('Cfops relacionados a este Cfop')"
              :placeholder="$t('Selecione')"
              :options="getComboAllCfop"
              :instructions="[
                {
                  text: $t(
                    'Habilita os Cfops selecionados como opções no campo Cfop dos itens na criação/edição da Nota Fiscal quando este Cfop for escolhido'
                  ),
                },
                { text: $t('Pode ser adicionado mais de um Cfop') },
              ]"
              multiple
            />
          </b-col>

          <b-col
            v-if="showNotAllowedStates"
            md="12"
          >
            <FormulateInput
              id="cfop-cfop_state_not_alloweds"
              v-model="cfop.cfopStateNotAlloweds"
              type="vue-select"
              :label="$t('Estados que não permitem referenciar cupom fiscal na Nota Fiscal')"
              :placeholder="$t('Selecione')"
              :options="getComboStatesIdValue"
              :instructions="[
                {
                  text: $t(
                    'Impede a criação de nota fiscal com referência a cupom fiscal caso o estado do destinatário seja um dos estados selecionados neste campo.'
                  ),
                },
              ]"
              multiple
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        v-if="isStockLosses || isStockTransfer"
        no-actions
        :show-loading="saving"
        :title="$t('Configuração fiscal especial')"
      >
        <cfop-custom-taxes-form
          :is-stock-losses="isStockLosses"
          :taxes="cfop.cfopCustomTaxes"
        />
      </b-card-actions>
    </FormulateForm>

    <e-crud-actions
      :busy="saving"
      @save="saveCfop"
      @cancel="$router.back()"
    />
  </section>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { invoiceDomains } from '@/mixins'
import { mapGetters, mapActions } from 'vuex'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ECrudActions from '@/views/components/ECrudActions.vue'
import CfopCustomTaxesForm from './components/CfopCustomTaxesForm.vue'

export default {
  components: {
    BCol,
    BRow,
    BCardActions,
    ECrudActions,
    CfopCustomTaxesForm,
  },
  mixins: [invoiceDomains],
  props: {},
  data() {
    return {
      isEdit: !!this.$router.currentRoute.params.id,
      saving: false,
      cfop: {
        code: '',
        description: '',
        movementType: '',
        cfopType: '',
        cfopInvoiceTypes: [],
        cfopRelatedCfops: [],
        cfopStateNotAlloweds: [],
        cfopCustomTaxes: [],
      },
    }
  },

  computed: {
    ...mapGetters('common/states', ['getComboStatesIdValue']),
    ...mapGetters('pages/invoice/taxClassifications', ['getComboAllCfop']),

    showNotAllowedStates() {
      return ['5929', '6929'].some(code => code === String(this.cfop.code))
    },
    isStockLosses() {
      return String(this.cfop.code) === '5927'
    },
    isStockTransfer() {
      return String(this.cfop.code) === '5152'
    },
  },

  async mounted() {
    try {
      this.fetchTaxClassificationCombos()
      this.fetchStates()

      if (this.isEdit) {
        const response = await this.$http.get(
          `/api/tax/cfops/${this.$router.currentRoute.params.id}`
        )
        this.cfop = this.componentDataAdapter(response.data)
      }
      this.showSidebar = true
    } catch (error) {
      this.showGenericError({ error })
    }
  },

  methods: {
    ...mapActions('common/states', ['fetchStates']),
    ...mapActions('pages/invoice/taxClassifications', ['fetchTaxClassificationCombos']),
    async saveCfop() {
      this.$refs.cfopForm.showErrors()
      if (this.$refs.cfopForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      try {
        this.saving = true

        const body = this.serverDataAdapter(this.cfop)

        await this.$http({
          url: '/api/tax/cfops',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })
        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.showSidebar = false
        this.$router.back()
        await this.$store.dispatch('pages/invoice/cfops/fetchCfops')
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    componentDataAdapter(data) {
      const cfopInvoiceTypes = data.cfopInvoiceTypes?.map(type => type.invoiceCategory) || []
      const cfopRelatedCfops =
        data.cfopRelatedCfops?.map(related => String(related.cfopRelatedCode)) || []
      const cfopStateNotAlloweds =
        data.cfopStateNotAlloweds?.map(related => String(related.stateId)) || []
      const cfopCustomTaxes = data.cfopCustomTaxes || []

      return {
        ...data,
        cfopInvoiceTypes,
        cfopRelatedCfops,
        cfopStateNotAlloweds,
        cfopCustomTaxes,
      }
    },
    serverDataAdapter(data) {
      const cfopInvoiceTypes =
        data.cfopInvoiceTypes?.map(type => ({ invoiceCategory: type })) || []
      const cfopRelatedCfops =
        data.cfopRelatedCfops?.map(related => ({ cfopRelatedCode: related })) || []
      const cfopStateNotAlloweds =
        data.cfopStateNotAlloweds?.map(stateNotAllowed => ({ stateId: stateNotAllowed })) || []

      const cfopCustomTaxes = data.cfopCustomTaxes || []

      return {
        code: parseInt(data.code, 10),
        description: data.description,
        movementType: data.movementType,
        cfopType: data.cfopType,
        active: data.active,
        cfopInvoiceTypes,
        cfopRelatedCfops,
        cfopStateNotAlloweds,
        cfopCustomTaxes,
      }
    },
  },
}
</script>
