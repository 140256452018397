<template>
  <div>
    <FormulateInput
      v-slot="{ index }"
      v-model="taxes"
      name="taxes"
      type="group"
      error-behavior="live"
      :repeatable="true"
      :add-label="$t('Adicionar configuração')"
      :limit="isStockLosses ? invoiceStockLossesReasonOptions().length : 1"
    >
      <b-row>
        <b-col
          v-if="isStockLosses"
          md="6"
        >
          <FormulateInput
            :id="`tax-loss-reason-${index}`"
            name="stockLossesReason"
            type="vue-select"
            :options="invoiceStockLossesReasonOptions()"
            :label="$t('Motivo baixa de estoque')"
            :title="$t('Selecione')"
            :validation="isStockLosses ? 'required' : ''"
            :class="{ required: isStockLosses }"
            :clearable="false"
            size="5"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            :id="`tax-st-ipi-${index}`"
            name="stIpi"
            type="vue-select"
            :label="$t('Cst Ipi Saída')"
            :placeholder="$t('Selecione')"
            :options="getComboCstIpiOutput"
            class="required"
            validation="required"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            :id="`tax-ipi-percent-${index}`"
            name="ipiPercent"
            type="text-percetage"
            validation="required|number|min:0"
            :label="$t('Alíquota Ipi')"
            class="required"
            currency="%"
            :precision="4"
          />
        </b-col>

        <b-col md="4">
          <FormulateInput
            :id="`tax-st-pis-${index}`"
            name="stPis"
            type="vue-select"
            :label="$t('Cst Pis Saída')"
            :placeholder="$t('Selecione')"
            :options="getComboCstPisCofinsOutput"
            class="required"
            validation="required"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            :id="`tax-pis-percent-${index}`"
            name="pisPercent"
            type="text-percetage"
            validation="required|number"
            :label="$t('Alíquota Pis')"
            class="required"
            currency="%"
            :precision="4"
          />
        </b-col>

        <b-col md="4">
          <FormulateInput
            :id="`tax-st-cofins-${index}`"
            name="stCofins"
            type="vue-select"
            :label="$t('Cst Cofins Saída')"
            :placeholder="$t('Selecione')"
            :options="getComboCstPisCofinsOutput"
            class="required"
            validation="required"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            :id="`tax-cofins-percent-${index}`"
            name="cofinsPercent"
            type="text-percetage"
            validation="required|number|min:0"
            :label="$t('Alíquota Cofins')"
            class="required"
            currency="%"
            :precision="4"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            :id="`tax-st-icms-${index}`"
            name="stIcms"
            type="vue-select"
            :label="$t('Cst Icms')"
            :placeholder="$t('Selecione')"
            :options="getComboCstIcms"
            class="required"
            validation="required"
          />
        </b-col>
        <b-col md="6">
          <FormulateInput
            :id="`tax-icms-csosn-${index}`"
            name="csosnIcms"
            type="vue-select"
            :label="$t('Csosn Icms')"
            :placeholder="$t('Selecione')"
            :options="getComboCsosnIcms"
          />
        </b-col>

        <b-col md="2">
          <FormulateInput
            :id="`tax-icms-percent-${index}`"
            name="icmsPercent"
            type="text-percetage"
            validation="required|number|min:0"
            currency="%"
            :precision="4"
            :label="$t('Alíquota Icms')"
            class="required"
          />
        </b-col>

        <b-col md="2">
          <FormulateInput
            :id="`tax-icmsBaseReduction-percent-${index}`"
            name="icmsBaseReductionPercent"
            type="text-percetage"
            :label="$t('Redução Base Icms')"
            validation="required|number|min:0"
            currency="%"
            :precision="4"
            class="required"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            :id="`tax-icms-iva-${index}`"
            name="iva"
            type="text-percetage"
            :label="$t('Iva')"
            class="required"
            validation="required|number|min:0"
            currency="%"
            :precision="4"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            :id="`tax-icms-st-percent-${index}`"
            name="icmsStPercent"
            type="text-percetage"
            :label="$t('Alíquota Icms ST')"
            class="required"
            validation="required|number|min:0"
            currency="%"
            :precision="4"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            :id="`tax-icms-st-base-reduction-percent-${index}`"
            name="icmsStBaseReductionPercent"
            type="text-percetage"
            :label="$t('Redução Base Icms ST')"
            class="required"
            validation="required|number|min:0"
            currency="%"
            :precision="4"
          />
        </b-col>

        <b-col md="3">
          <FormulateInput
            :id="`tax-icms-fcp-percent-${index}`"
            name="fcpPercent"
            type="text-percetage"
            :label="$t('Alíquota Fundo de Combate à Pobreza')"
            validation="required|number|min:0"
            class="required"
            currency="%"
            :precision="4"
          />
        </b-col>

        <b-col md="3">
          <FormulateInput
            :id="`tax-icms-fcp-st-percent-${index}`"
            name="fcpStPercent"
            type="text-percetage"
            :label="$t('Alíquota Fundo de Combate à Pobreza ST')"
            validation="required|number|min:0"
            class="required"
            currency="%"
            :precision="4"
          />
        </b-col>
      </b-row>
    </FormulateInput>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { invoiceDomains } from '@/mixins'

export default {
  name: 'CfopCustomTaxesForm',
  components: { BCol, BRow },
  mixins: [invoiceDomains],
  props: {
    isStockLosses: {
      type: Boolean,
      default: false,
    },
    taxes: {
      type: Array,
      default: null,
    },
  },

  computed: {
    ...mapGetters('pages/invoice/taxClassifications', [
      'getCstIcms',
      'getCsosnIcms',
      'getComboCstPisCofinsOutput',
      'getComboCstIpiOutput',
    ]),

    getComboCstIcms() {
      return this.getCstIcms.map(c => ({
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
    getComboCsosnIcms() {
      return this.getCsosnIcms.map(c => ({
        label: `${c.code} - ${c.description}`,
        value: c.code,
      }))
    },
  },

  methods: {},
}
</script>

<style></style>
