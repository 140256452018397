var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { ref: "cfopForm", attrs: { name: "cfopForm" } },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "no-actions": "",
                "show-loading": _vm.saving,
                title: _vm.$t("Dados do Cfop"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "code",
                          type: _vm.isEdit ? "label" : "number",
                          minlength: "4",
                          maxlength: "4",
                          oninput:
                            "javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);",
                          label: _vm.$t("Código"),
                          placeholder: _vm.$t("Código"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.cfop.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.cfop, "code", $$v)
                          },
                          expression: "cfop.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "5" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "description",
                          type: "textarea",
                          rows: "3",
                          label: _vm.$t("Descrição"),
                          instruction: _vm.$t("Informe a descrição do CFOP"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.cfop.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.cfop, "description", $$v)
                          },
                          expression: "cfop.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "movement-type",
                          type: "vue-select",
                          label: _vm.$t("Tipo de Movimento"),
                          placeholder: _vm.$t("Selecione um tipo de movimento"),
                          options: _vm.movementTypeOptions(),
                          validation: "required",
                        },
                        model: {
                          value: _vm.cfop.movementType,
                          callback: function ($$v) {
                            _vm.$set(_vm.cfop, "movementType", $$v)
                          },
                          expression: "cfop.movementType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "cfop-type",
                          type: "vue-select",
                          label: _vm.$t("Tipo de Cfop"),
                          placeholder: _vm.$t("Selecione um tipo de cfop"),
                          options: _vm.cfopTypeOptions(),
                          validation: "required",
                        },
                        model: {
                          value: _vm.cfop.cfopType,
                          callback: function ($$v) {
                            _vm.$set(_vm.cfop, "cfopType", $$v)
                          },
                          expression: "cfop.cfopType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                "no-actions": "",
                "show-loading": _vm.saving,
                title: _vm.$t(
                  "Configuração para utilização em funcionalidades do sistema"
                ),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "cfop-invoice_types",
                          type: "vue-select",
                          label: _vm.$t("Tipo de Nota Fiscal relacionado"),
                          placeholder: _vm.$t("Selecione"),
                          options: _vm.invoiceCategoryOptions(),
                          instructions: [
                            {
                              text: _vm.$t(
                                "Habilita este Cfop como opção no campo Natureza da Operação de acordo com o tipo da Nota Fiscal a ser criado/editado"
                              ),
                            },
                            {
                              text: _vm.$t(
                                "Pode ser adicionado mais de um tipo de Nota Fiscal"
                              ),
                            },
                          ],
                          multiple: "",
                        },
                        model: {
                          value: _vm.cfop.cfopInvoiceTypes,
                          callback: function ($$v) {
                            _vm.$set(_vm.cfop, "cfopInvoiceTypes", $$v)
                          },
                          expression: "cfop.cfopInvoiceTypes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "cfop-cfop_relateds",
                          type: "vue-select",
                          label: _vm.$t("Cfops relacionados a este Cfop"),
                          placeholder: _vm.$t("Selecione"),
                          options: _vm.getComboAllCfop,
                          instructions: [
                            {
                              text: _vm.$t(
                                "Habilita os Cfops selecionados como opções no campo Cfop dos itens na criação/edição da Nota Fiscal quando este Cfop for escolhido"
                              ),
                            },
                            {
                              text: _vm.$t(
                                "Pode ser adicionado mais de um Cfop"
                              ),
                            },
                          ],
                          multiple: "",
                        },
                        model: {
                          value: _vm.cfop.cfopRelatedCfops,
                          callback: function ($$v) {
                            _vm.$set(_vm.cfop, "cfopRelatedCfops", $$v)
                          },
                          expression: "cfop.cfopRelatedCfops",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.showNotAllowedStates
                    ? _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "cfop-cfop_state_not_alloweds",
                              type: "vue-select",
                              label: _vm.$t(
                                "Estados que não permitem referenciar cupom fiscal na Nota Fiscal"
                              ),
                              placeholder: _vm.$t("Selecione"),
                              options: _vm.getComboStatesIdValue,
                              instructions: [
                                {
                                  text: _vm.$t(
                                    "Impede a criação de nota fiscal com referência a cupom fiscal caso o estado do destinatário seja um dos estados selecionados neste campo."
                                  ),
                                },
                              ],
                              multiple: "",
                            },
                            model: {
                              value: _vm.cfop.cfopStateNotAlloweds,
                              callback: function ($$v) {
                                _vm.$set(_vm.cfop, "cfopStateNotAlloweds", $$v)
                              },
                              expression: "cfop.cfopStateNotAlloweds",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isStockLosses || _vm.isStockTransfer
            ? _c(
                "b-card-actions",
                {
                  attrs: {
                    "no-actions": "",
                    "show-loading": _vm.saving,
                    title: _vm.$t("Configuração fiscal especial"),
                  },
                },
                [
                  _c("cfop-custom-taxes-form", {
                    attrs: {
                      "is-stock-losses": _vm.isStockLosses,
                      taxes: _vm.cfop.cfopCustomTaxes,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("e-crud-actions", {
        attrs: { busy: _vm.saving },
        on: {
          save: _vm.saveCfop,
          cancel: function ($event) {
            return _vm.$router.back()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }